<template>
  <d-edit-card
    ref="editForm"
    :entity="form"
    :submit-function="feeController.createFee"
    card-title="Create balance values"
    submit-success-title="Balance values successfully created!"
    redirect-after-creation="LimitsList"
  >
    <template #default="{ data }">
      <q-card-section class="col">
        <div class="row">
          <div class="col-xs-12 col-md-4 q-col-gutter-md">
            <d-user-select
              emit-value
              map-options
              :dense="$q.screen.lt.md"
              v-model="data.user.id"
              :options="agents"
              label="Agent"
              class="col-12"
              hint="Required field*"
              :multiple="false"
              :rules="[rules.notEmpty]"
            />
            <q-input
              outlined
              hint="Required field*"
              label="Balance limit"
              v-model="data.balanceLimit"
              :dense="$q.screen.lt.md"
              :rules="[rules.notEmpty]"
            />
            <q-input
              outlined
              hint="Required field*"
              label="Agent commission for deposit %"
              v-model="data.refillPercent"
              :dense="$q.screen.lt.md"
              :rules="[rules.notEmpty]"
            />
            <q-input
              outlined
              hint="Required field*"
              label="Agent commission for deposit, fixed"
              v-model="data.refillFix"
              :rules="[rules.notEmpty]"
              :dense="$q.screen.lt.md"
            />
            <q-input
              outlined
              hint="Required field*"
              label="Agent fee for payout %"
              v-model="data.payoutPercent"
              :dense="$q.screen.lt.md"
              :rules="[rules.notEmpty]"
            />
            <q-input
              outlined
              hint="Required field*"
              label="Agent fee for payout, fixed"
              v-model="data.payoutFix"
              :dense="$q.screen.lt.md"
              :rules="[rules.notEmpty]"
            />
            <q-input
              outlined
              hint="Required field*"
              label="Commission for advertising payments %"
              v-model="data.adPercent"
              :dense="$q.screen.lt.md"
              :rules="[rules.notEmpty]"
            />
            <q-input
              outlined
              hint="Required field*"
              label="% Settlement (prepayment)"
              v-model="data.prepaymentPercent"
              :dense="$q.screen.lt.md"
              :rules="[rules.notEmpty]"
            />
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ loading }">
      <d-edit-form-actions :disable="loading" />
    </template>
  </d-edit-card>
</template>

<script>
import { DEditFormActions } from '@/features/edit-form-actions';
import DUserSelect from '@/features/user-select';
import DEditCard from '@/layouts/edit-card';
import { feeController, usersController } from '@/shared/api';
import { authUtils, notify } from '@/shared/utils';

export default {
  components: {
    DEditCard,
    DEditFormActions,
    DUserSelect,
  },
  data: () => ({
    agents: [],
    feeController: feeController(authUtils.getRoleByHierarchy()),
    form: {
      user: {
        id: null,
      },
      balanceLimit: '',
      refillPercent: '',
      refillFix: '',
      payoutPercent: '',
      payoutFix: '',
      adPercent: '',
      prepaymentPercent: '',
    },
    rules: {
      notEmpty: (val) => !!val || 'Please type value',
    },
  }),
  async mounted() {
    try {
      this.$refs.editForm.setLoading(true);
      const { data } = await usersController(
        authUtils.getRoleByHierarchy()
      ).agentsForFilter();
      this.agents = data;
    } catch (e) {
      notify.error('Dictionary loading error');
    } finally {
      this.$refs.editForm.setLoading(false);
    }
  },
};
</script>
